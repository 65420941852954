// src/App.js
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";

import About from "./components/header/About";
import Layout from './Layout';



import UserProfilePage from "./user/UserProfilePage.jsx"; // User profile page
import CourseDetailPage from './user/CourseDetailPage';


function App() {
  return (
    <Router>
      <div className="App">
        <Layout>
          <Routes>
            
            <Route path="/Home" element={<Home />} />
            
           
            <Route path="/About" element={<About />} />

            <Route path="/profile" element={<UserProfilePage />} />
            <Route path="/course/:courseId" element={<CourseDetailPage />} />
           
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}

export default App;