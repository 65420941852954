import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./hero-section.css";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { db } from "../../FB/firebaseConfig"; // Import the Firebase configuration
import { collection, addDoc } from "firebase/firestore"; // Import Firestore methods
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth"; // Import Firebase Auth

const HeroSection = () => {
  const [modal, setModal] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  });
  const navigate = useNavigate(); // Initialize useNavigate

  const toggleModal = () => setModal(!modal);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (isLogin) {
      // Login logic
      try {
        const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
        navigate("/profile"); // Navigate to profile page after login
        toggleModal(); // Close the modal
      } catch (error) {
        alert("Error logging in. Please check your credentials.");
      }
    } else {
      // Sign up logic
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        await addDoc(collection(db, "users"), {
          uid: userCredential.user.uid,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
        });
        alert("User signed up successfully!");
        navigate("/profile"); // Navigate to profile page after sign-up
        toggleModal(); // Close the modal
        setFormData({ firstName: "", lastName: "", email: "", password: "", phone: "" }); // Reset form
      } catch (error) {
        console.error("Error signing up: ", error);
        alert("Error signing up. Please try again.");
      }
    }
  };

  return (
    <section className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 animated fadeIn">Altafawoq Academy</h2>
              <p className="mb-4 animated fadeIn">
                To all those who aspire to excel in the English Language, we are here at Altafawoq Academy to achieve your goals and turn your dreams into reality. 
                With excellence, mastery, and experience, we will work together to achieve the highest grades at STEP Test, IELTS Test, and PYD.
                We offer excellent courses (Basic, Intermediate, Advanced) face-to-face at our Academy or online on our platform. 
                We also provide certified international English language courses with authorized certificates for everyone who wants to learn or improve their English skills (Reading, Speaking, Listening, Writing) from beginner to advanced levels.
                This is facilitated by certified international teachers using modern techniques, whether face-to-face at our academy or online.
              </p>
              <Button color="primary" onClick={toggleModal}>Register / Login</Button>
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{isLogin ? "Login" : "create account"}</ModalHeader>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    {!isLogin && (
                      <>
                        <div className="form-group">
                          <label>First Name</label>
                          <input 
                            type="text" 
                            name="firstName" 
                            className="form-control" 
                            placeholder="Enter your first name" 
                            value={formData.firstName} 
                            onChange={handleChange} 
                            required 
                          />
                        </div>
                        <div className="form-group">
                          <label>Last Name</label>
                          <input 
                            type="text" 
                            name="lastName" 
                            className="form-control" 
                            placeholder="Enter your last name" 
                            value={formData.lastName} 
                            onChange={handleChange} 
                            required 
                          />
                        </div>
                        <div className="form-group">
                          <label>Phone Number (+XX)</label>
                          <input 
                            type="tel" 
                            name="phone" 
                            className="form-control" 
                            placeholder="Enter your phone number" 
                            value={formData.phone} 
                            onChange={handleChange} 
                            required 
                          />
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <label>Email</label>
                      <input 
                        type="email" 
                        name="email" 
                        className="form-control" 
                        placeholder="Enter your email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input 
                        type="password" 
                        name="password" 
                        className="form-control" 
                        placeholder="Enter your password" 
                        value={formData.password} 
                        onChange={handleChange} 
                        required 
                      />
                    </div>
                    {!isLogin && (
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input 
                          type="password" 
                          name="confirmPassword" 
                          className="form-control" 
                          placeholder="Confirm your password" 
                          required 
                        />
                      </div>
                    )}
                    <Button color="primary" type="submit">{isLogin ? "Login" : "create account"}</Button>
                  </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={handleToggle}>
                    {isLogin ? "create account" : "Switch to Login"}
                  </Button>
                  <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;