import React, { useRef } from "react";
import { Container } from "reactstrap";
import "./header.css";
import logo from '../../images/WhatsApp_Image_2024-09-19_at_20.08.01_2e4e8613-removebg-preview.png'; // Update with your logo path

const navLinks = [
  { display: "Home", url: "/Home" },
  { display: "About us", url: "/About" },

 
];

const Header = () => {
  const menuRef = useRef();
  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <header className="header">
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo d-flex align-items-center">
            <img src={logo} alt="Logo" className="logo-image" />
            <h2>Altafawoq</h2>
          </div>
          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((item, i) => (
                  <li key={i} className="nav__item">
                    <a href={item.url}>{item.display}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mobile__menu">
              <span>
                <i className="ri-menu-line" onClick={menuToggle}></i>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;








