import React, { useEffect, useState } from "react";
import { db, storage } from "../FB/firebaseConfig";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL } from "firebase/storage";
import styles from './UserProfile.module.css';
import Exams from './Exams'; // New Exams component

const UserProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [liveVideos, setLiveVideos] = useState([]); // New state for live videos
  const [activeSection, setActiveSection] = useState('profile');
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchData(user.uid);
      } else {
        navigate("/"); // Redirect to home if not authenticated
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchData = async (userId) => {
    // Fetch user data
    const userRef = collection(db, "users");
    const userQuery = query(userRef, where("uid", "==", userId));
    const userSnapshot = await getDocs(userQuery);
    const userDoc = userSnapshot.docs[0]?.data();
    setUserData(userDoc);

    // Fetch enrolled courses
    const enrolledQuery = query(collection(db, "enrollments"), where("userId", "==", userId));
    const enrolledSnapshot = await getDocs(enrolledQuery);
    const enrolledData = enrolledSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setEnrolledCourses(enrolledData);

    // Fetch available courses
    const allCoursesQuery = collection(db, "courses");
    const allCoursesSnapshot = await getDocs(allCoursesQuery);
    const allCoursesData = await Promise.all(
      allCoursesSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        let imageUrl = "";

        try {
          imageUrl = await getDownloadURL(ref(storage, data.photoUrl));
        } catch (error) {
          console.warn(`Image not found for course ${data.name}:`, error);
          imageUrl = "path/to/default/image.png";
        }

        return {
          id: doc.id,
          name: data.name,
          description: data.description || "No description available",
          tutorName: data.tutorName || "Unknown",
          price: data.price || "Free",
          imageUrl: imageUrl,
        };
      })
    );
    setAvailableCourses(allCoursesData);

    // Fetch live videos for the user
    const liveVideosQuery = query(collection(db, "liveVideos"), where("userId", "==", userId));
    const liveVideosSnapshot = await getDocs(liveVideosQuery);
    const liveVideosData = liveVideosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLiveVideos(liveVideosData);
  };

  const handleEnroll = async (course) => {
    if (!auth.currentUser || enrolledCourses.some(c => c.courseId === course.id)) return;

    const userId = auth.currentUser.uid;
    const userEmail = auth.currentUser.email; // Get the email
    const userName = `${userData.firstName} ${userData.lastName}`; // Get the name
    const userPhone = userData.phone; // Get the phone number

    // Add the course to the enrolled courses
    await addDoc(collection(db, "enrollments"), {
      userId: userId,
      email: userEmail, // Store the email
      userName: userName, // Store the name
      phone: userPhone, // Store the phone number
      courseId: course.id,
      courseName: course.name, // Store the course name
    });

    // Update local state
    setEnrolledCourses(prevCourses => [...prevCourses, { courseId: course.id, ...course }]);
  };

  const handleLogout = async () => {
    await auth.signOut();
    navigate("/Home");
  };

  const openCourse = (courseId) => {
    navigate(`/course/${courseId}`); // Navigate to the course detail page
  };

  return (
    <div className={styles.userProfile}>
      <aside className={styles.sidebar}>
        <h2>User Profile</h2>
        <div className={styles.buttonContainer}>
          <button onClick={() => setActiveSection('profile')} className={styles.navButton}>View Profile</button>
          <button onClick={() => setActiveSection('enrolled')} className={styles.navButton}>Enrolled Courses</button>
          <button onClick={() => setActiveSection('courses')} className={styles.navButton}>Courses</button>
          <button onClick={() => setActiveSection('live')} className={styles.navButton}>Live Videos</button>
          <button onClick={() => setActiveSection('exams')} className={styles.navButton}>Exams</button> {/* New Exams Button */}
          <button onClick={() => setActiveSection('support')} className={styles.navButton}>Support</button>
          <button onClick={handleLogout} className={styles.navButton}>Logout</button>
        </div>
      </aside>

      <main className={styles.mainContent}>
        {activeSection === 'profile' && (
          <div>
            <h2>User Profile</h2>
            {userData && (
              <div className={styles.userInfo}>
                <p><strong>Name:</strong> {userData.firstName} {userData.lastName}</p>
                <p><strong>Email:</strong> {userData.email}</p>
                <p><strong>Phone:</strong> {userData.phone}</p>
              </div>
            )}
          </div>
        )}
        {activeSection === 'enrolled' && (
          <section className={styles.enrolledCoursesSection}>
            <h3>Your Enrolled Courses</h3>
            <ul className={styles.courseList}>
              {enrolledCourses.length > 0 ? (
                enrolledCourses.map(enrollment => {
                  const course = availableCourses.find(c => c.id === enrollment.courseId);
                  return course ? (
                    <li key={enrollment.id} className={styles.courseCard}>
                      <img src={course.imageUrl} alt={course.name} className={styles.courseImage} />
                      <h2>{course.name}</h2>
                      <p>👨‍🏫 Tutor: {course.tutorName}</p>
                      <p>📝 Description: {course.description}</p>
                      <button onClick={() => openCourse(course.id)} className={styles.enrollButton}>Open</button>
                    </li>
                  ) : null;
                })
              ) : (
                <li>No enrolled courses found.</li>
              )}
            </ul>
          </section>
        )}
        {activeSection === 'courses' && (
          <section className={styles.availableCoursesSection}>
            <h3>Available Courses</h3>
            <ul className={styles.courseList}>
              {availableCourses.length > 0 ? (
                availableCourses.map(course => (
                  <li key={course.id} className={styles.courseCard}>
                    <img src={course.imageUrl} alt={course.name} className={styles.courseImage} />
                    <h2>{course.name}</h2>
                    <p>Price: {course.price} RYL</p>
                    <p>👨‍🏫 Tutor: {course.tutorName}</p>
                    <p>📝 Description: {course.description}</p>
                    <button onClick={() => handleEnroll(course)} className={styles.enrollButton}>Enroll Now</button>
                  </li>
                ))
              ) : (
                <li>No courses available.</li>
              )}
            </ul>
          </section>
        )}
        {activeSection === 'live' && (
          <section className={styles.liveVideosSection}>
            <h3>Live Videos</h3>
            {liveVideos.length > 0 ? (
              <ul className={styles.videoList}>
                {liveVideos.map(video => (
                  <li key={video.id} className={styles.videoCard}>
                    <h4>{video.courseName}</h4>
                    <video controls src={video.liveVideoUrl} className={styles.videoPlayer}></video>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No live videos available yet.</p>
            )}
          </section>
        )}
        {activeSection === 'exams' && (
          <section className={styles.examsSection}>
            <h3>Available Exams</h3>
            <Exams /> {/* Render the Exams component */}
          </section>
        )}
        {activeSection === 'support' && (
          <section className={styles.supportContactsSection}>
            <h3>Support Contacts</h3>
            <p>If you need assistance, please contact us at:</p>
            <p>Email: support@example.com</p>
            <p>Phone: 123-456-7890</p>
          </section>
        )}
      </main>
    </div>
  );
};

export default UserProfilePage;