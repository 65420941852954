import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import { db } from "../../FB/firebaseConfig"; // Import Firestore configuration
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import "./footer.css";

const footerInfoLinks = [
  {
    display: "Privacy Policy",
    url: "#",
  },
  {
    display: "Terms of Service",
    url: "#",
  },
];

const Footer = () => {
  const [modal, setModal] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState({ english: "", arabic: "" });

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      const docRef = doc(db, "privacyPolicy", "k9BcxVtlfnuUhrJGFBxY"); // Replace with your actual document ID
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPrivacyPolicy(docSnap.data()); // This will hold both english and arabic fields
      } else {
        console.log("No such document!");
      }
    };

    fetchPrivacyPolicy();
  }, []);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="mb-4">
            <h2 className="d-flex align-items-center gap-1">TAFWOK-EDU</h2>
            <div className="follows">
              <p className="mb-0">Follow us on social media</p>
              <span>
                <a href="https://www.facebook.com/profile.php?id=61566476404242" target="_blank" rel="noopener noreferrer">
                  <i className="ri-facebook-line"></i>
                </a>
              </span>
              <span>
                <a href="https://www.instagram.com/altafawoqa" target="_blank" rel="noopener noreferrer">
                  <i className="ri-instagram-line"></i>
                </a>
              </span>
              <span>
                <a href="https://x.com/AltafawoqA" target="_blank" rel="noopener noreferrer">
                  <i className="ri-twitter-line"></i>
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="6">
            <h6 className="fw-bold">Support</h6>
            <p>Email: support@altafawoqacademy.co</p>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Information</h6>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  <a href={item.url} onClick={item.display === "Privacy Policy" ? toggleModal : undefined}>
                    {item.display}
                  </a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6">
            <h6 className="fw-bold">Get in Touch</h6>
            <p>Address: Altafawoq Academy, DAMMAM, SAUDI ARABIA</p>
            <p>Phone: +966 507996099</p>
            <p>Email: altafawoq.info@gmail.com</p>
          </Col>
        </Row>
      </Container>

      {/* Modal for Privacy Policy */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Privacy Policy</ModalHeader>
        <ModalBody>
          <p>{privacyPolicy.english}</p>
          <hr />
          <p>{privacyPolicy.arabic}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </footer>
  );
};

export default Footer;