import React from "react";
import "./features.css";
import { Container, Row, Col } from "reactstrap";

const FeatureData = [
  {
    title: "Quick Learning",
    desc: "Our programs, including PYD English and IELTS Preparation, are designed for rapid skill acquisition. With structured lessons and practical exercises, you will see significant improvement in your English proficiency in a short time.",
    icon: "ri-draft-line",
  },
  {
    title: "All Time Support",
    desc: "We offer continuous support to our students through various channels. Whether you have questions about the STEP Preparation or need assistance with your coursework, our dedicated team is available to help you anytime.",
    icon: "ri-discuss-line",
  },
  {
    title: "Certification",
    desc: "Upon successful completion of our courses, including the IELTS Preparation program, you will receive a recognized certification. This credential will enhance your resume and demonstrate your language skills to potential employers or educational institutions.",
    icon: "ri-contacts-book-line",
  },
];

const Features = () => {
  return (
    <section>
      <Container>
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" key={index}>
              <div className="single__feature text-center px-4">
                <h2 className="mb-3">
                  <i className={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;