import React from "react";

import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../images/WhatsApp_Image_2024-09-19_at_20.08.01_2e4e8613-removebg-preview.png";
import "./css.css"
const About = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="about__content">
              <h2>About us</h2>
              <p>
              Altafawoq Academy is a specialized educational institution for teaching English language to all 
              levels and ages.  We introduce courses for anyone who wants to apply for STEP, ILETS and 
               TOFEL.  Also, we introduce courses to those who want to learn English or develop their skills.  
               The Academy works under the supervision of the Ministry of Education and authorized from the 
               Saudi Educational Ministry of Education.  Our curriculum is certified and authorized from the 
              Ministry of Education.  
              </p>
          
                </div>
              
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
