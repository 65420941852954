import React, { useState } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Slider from "react-slick"; // Make sure to install react-slick and slick-carousel
import "./choose.css";

// Import course images
import englishBeginnersImg from "../../images/R.jpeg"; // Replace with actual image path
import businessEnglishImg from "../../images/branding.png"; // Replace with actual image path
import ieltsPrepImg from "../../images/ielts.png"; // Replace with actual image path

const ChooseUs = () => {
  const [modal, setModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");

  const toggleModal = () => setModal(!modal);

  const handleCourseClick = (title) => {
    setCourseTitle(title);
    toggleModal();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Duration between slides (in milliseconds)
    pauseOnHover: true, // Pause autoplay on hover
    arrows: true, // Show navigation arrows
  };

  const courses = [
    {
      title: "PYD English program",
      description: "Focuses on the four language skills (reading, listening, speaking, and writing).",
      location: "Online",
      image: englishBeginnersImg,
    },
    {
      title: "STEP Preparation",
      description: "With STEP, you’ll find yourself speaking, writing, and interacting confidently. STEP determines your current level and helps you improve your English.",
      location: "Online",
      image: businessEnglishImg,
    },
    {
      title: "IELTS Preparation",
      description: "Comprehensive training to help you excel in the IELTS exam.",
      location: "Online",
      image: ieltsPrepImg,
    },
  ];

  return (
    <section>
      <Container>
        <Row>
          <Col lg="6">
            <div className="choose__content">
              <h2>Why Choose Us</h2>
              <p>
                If you are looking for excellence. <br /> We will teach you the English language using the most 
                advanced and up-to-date teaching techniques. Whether face-to-face or online, we will provide 
                you with the best ways to acquire the language and help you learn easily and smoothly. In 
                Altafawq Academy, we work under a slogan (Practice makes perfect). As a result, you will 
                receive intensive care and follow-up which will make you learn English quickly and perfectly.  
                Our excellent and specialized teachers whom we've selected very carefully will teach you in a 
                very easy and effective way.   
              </p>
            </div>
          </Col>
          <Col lg="6">
            <div className="choose__courses-card">
              <h2 className="courses-title">Our Popular Courses</h2>
              <Slider {...settings}>
                {courses.map((course, index) => (
                  <div key={index} className="single__course" onClick={() => handleCourseClick(course.title)}>
                    <img src={course.image} alt={course.title} className="course-image" />
                    <h6 className="mb-3 fw-bold">{course.title}</h6>
                    <p>{course.description}</p>
                    <div className="course__info mt-4">
                      <p>Location: {course.location}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for Sign In Prompt */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Sign In Required</ModalHeader>
        <ModalBody>
          Please create account first to view details for {courseTitle}.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
          {/* You can add a link to the sign-in page here */}
          
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default ChooseUs;